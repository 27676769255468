import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ZoomLineChart from "../components/zoomlinechart"
import ZoomLineChart2 from "../components/zoomlinechart2"
import ZoomLineChart3 from "../components/charttest1"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Link to="/rechartstest">Rechartsテスト</Link>
    <br/>
    <Link to="/d3test">d3テスト</Link>
    <br/>
    <Link to="/d3test2">d3テスト2</Link>
    <br/>
    <ZoomLineChart />
  </Layout>
)

export default IndexPage
